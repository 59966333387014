import axios from "axios";

const ApiService = axios.create({
  baseURL: "https://softbeez-api-v1.onrender.com/api/v1/",
});

export const getAllEmployees = async () => {
  try {
    return await ApiService.get("/employees/all-employee");
  } catch (err) {
    console.log(err?.message);
  }
};
