import React from "react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="menu-item-has-children">
          {/* <a href='#services'>Services</a> */}
          <Link to="#services">
            Services <FaAngleDown />{" "}
          </Link>
          <ul className="axil-submenu">
            {/* <li><Link to={"/service-details/uiux-design"}>UI/UX Design</Link></li> */}
            <li>
              <Link to={"/service-details/web-design-development"}>
                Web Design & Development
              </Link>
            </li>
            <li>
              <Link to={"/service-details/graphic-design"}>
                Graphics Design
              </Link>
            </li>
            {/* <li><Link to={"/service-details/apps-development"}>App Development</Link></li> */}
            <li>
              <Link to={"/service-details/digital-marketing"}>
                Digital Marketing
              </Link>
            </li>
            {/* <li><Link to={"/service-details/it-consultancy"}>It Consultancy</Link></li> */}
          </ul>
        </li>
        <li className="menu-item-has-children">
          <a href="#about-us">About us</a>
          {/* <Link to="#">Services <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={"/service-one"}>Service</Link></li>
                        <li><Link to={"/service-two"}>Service Two</Link></li>
                        <li><Link to={"/service-details/design"}>Service Details</Link></li>
                    </ul> */}
        </li>
        <li className="menu-item-has-children">
          <a href="#portfolio">Portfolio</a>
          {/* <Link to="#">Portfolio <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={"/project-grid-one"}>Two Column</Link></li>
                        <li><Link to={"/project-grid-two"}>Three Column</Link></li>
                        <li><Link to={"/project-grid-three"}>Four Column</Link></li>
                        <li><Link to={"/project-width-one"}>Three Column Width</Link></li>
                        <li><Link to={"/project-width-two"}>Four Column Width</Link></li>
                        <li><Link to={"/project-details/plan-management"}>Portfolio Details</Link></li>
                    </ul> */}
        </li>
        {/* <li className="menu-item-has-children">
                    <Link to="#">Pages <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={"/about-us"}>About Us</Link></li>
                        <li><Link to={"/our-office"}>Our Office</Link></li>
                        <li><Link to={"/case-study"}>Case Study</Link></li>
                        <li><Link to={"/case-details/whitehorse"}>Case Study Details</Link></li>
                        <li><Link to={"/team"}>Team</Link></li>
                        <li><Link to={"/team-details/jane-cooper"}>Team Details</Link></li>
                        <li><Link to={"/our-clients"}>Our Clients</Link></li>
                        <li><Link to={"/testimonials"}>Testimonial</Link></li>
                        <li><Link to={"/pricing-table"}>Pricing Table</Link></li>
                        <li><Link to={"/typography"}>Typography</Link></li>
                        <li><Link to={"/404"}>404 Page</Link></li>
                        <li><Link to={"/coming-soon"}>Coming Soon</Link></li>
                    </ul>
                </li> */}
        {/* <li className="menu-item-has-children">
                    <Link to="#">Blog <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={"/blog-grid"}>Blog</Link></li>
                        <li><Link to={"/blog-details/1"}>Standard Post</Link></li>
                        <li><Link to={"/blog-details/2"}>Gallery Post</Link></li>
                        <li><Link to={"/blog-details/3"}>Video Post</Link></li>
                        <li><Link to={"#"}>Audio Post</Link></li>
                        <li><Link to={"#"}>Quote Post</Link></li>
                    </ul>
                </li> */}
        <li>
          <Link to={"/contact"}>Contact</Link>
        </li>
        <li className="menu-item-has-children">
          <a href="/team">Our Team</a>
        </li>

        <li
          className=""
          style={{
            padding: "5px 40px",
            borderRadius: "50px",
            background: "rgb(89, 86, 233)",
            color: "#fff",
          }}
        >
          <a style={{ color: "#fff" }} href="/appointment">
            Schedule a meeting
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
