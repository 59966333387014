import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TeamData from "../../data/team/TeamData.json";
import { slugify } from "../../utils";
import { getAllEmployees } from "../../services/apiService";

const allData = TeamData;

const TeamOne = () => {
  const [allTeam, setAllTeam] = useState([]);

  // const fetchAllTeam = async () => {
  //   const res = await getAllEmployees();
  //   console.log("team", res?.data);
  //   setAllTeam(res?.data || []);
  // };
  // useEffect(() => {
  //   fetchAllTeam();
  // }, []);

  return (
    <div className="section section-padding bg-color-dark pb--70 pb_lg--20 pb_md--0">
      <div className="container">
        <div className="section-heading heading-light">
          <span className="subtitle">Our values</span>
          <h2 className="title mb--50">Meet The Team</h2>
          {/* <p>Nulla facilisi. Nullam in magna id dolor 
                blandit rutrum eget vulputate augue sed eu leo eget risus imperdiet.</p> */}
        </div>
        <div className="row">
          {allData?.map((data) => (
            <div className="col-xl-3 col-sm-6" key={data?.id}>
              <div className="team-grid">
                <div className="thumbnail">
                  <Link to={`#`}>
                    <img src={data?.thumb} alt={data?.name} />
                  </Link>
                </div>
                <div className="content">
                  <h4 className="title">{data?.title}</h4>
                  <span
                    className="designation"
                    dangerouslySetInnerHTML={{ __html: data?.designation }}
                  ></span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img src={"/images/others/circle-1.png"} alt="Circle" />
        </li>
        <li className="shape shape-2">
          <img src={"/images/others/line-3.png"} alt="Circle" />
        </li>
        <li className="shape shape-3">
          <img src={"/images/others/bubble-5.png"} alt="Circle" />
        </li>
      </ul>
    </div>
  );
};

export default TeamOne;
