import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';


const AboutTwo = () => {
    return (
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <div className="section-heading heading-left">
                                <span className="subtitle">About Us</span>
                                <h3 className="title">What’s the Beauty of Our Products?</h3>
                                <ul>
                                    <li>Reduced costs</li>

                                    <li>Quality services</li>

                                    <li>Unmatchable solutions all at one place</li>

                                    <li>Thoughtful development cycle</li>

                                    <li>You’re our first priority. Everything else can wait</li>

                                    <li>Maximum quality in minimum time frame</li>


                                    <li>We understand what your business means to you, so we thoroughly discuss to know your needs considering the target user needs and modern market trends.</li>
                                </ul>
                            </div>
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><FaCompress /> Concept & Strategies</Accordion.Header>
                                    <Accordion.Body>
                                        We initiate legitimate strategies and promising ideas tailored to your needs.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><FaCode /> Design and Development</Accordion.Header>
                                    <Accordion.Body>
                                        Designing & Developing just the appropriate is our forte and will compliment your requirements.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><FaGlobe /> Deploy</Accordion.Header>
                                    <Accordion.Body>
                                       Delivering the Perfection with sincere efforts and worthy output
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box shadow-box mb--30">
                            <h3 className="title">Get a free quote now</h3>
                            <FormOne />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;