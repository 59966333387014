import React from 'react';
import FormOne from '../contact/FormOne';


const AboutOne = () => {
    return (
        <section id="about-us" className="section section-padding-equal bg-color-light">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">About Us</span>
                                <h2 className="title mb--40">Elevate Your Digital Presence with Us</h2>
                                <p>Softbeez is a one-stop solution for all your digital needs. We offer a range of services, including website development, app development, digital marketing, and graphic design. With a team of skilled professionals, we strive to deliver innovative and effective solutions to meet the unique requirements of each of our clients.</p>
                                <p>Our mission is to deliver customer satisfaction through innovative IT solutions. We strive to close the gap between our services and client demands by offering customized, unique, and dynamic solutions. Contact us for a tailored approach to your business needs.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">Get a free quote now</h3>
                           <FormOne />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={"/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={"/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={"/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default AboutOne;