import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import CounterUp from './CounterUp';


const CounterUpOne = () => {
    return (
        <div className="section section-padding bg-color-dark">
            <div className="container">
            <SectionTitle 
                    subtitle="Featured Case Study"
                    title="Have a Project in Mind?"
                    description="We understand what your business means to you, so we thoroughly discuss to know your needs considering the target user needs and modern market trends."
                    textAlignment="heading-light"
                    textColor=""
                />
                <div className="row">
                    <CounterUp colSize="col-lg-4 col-6" layoutStyle="" evenTopMargin="" />
                </div>
            </div>
        </div>
    )
}


export default CounterUpOne;