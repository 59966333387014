import React from "react";
import { InlineWidget } from "react-calendly";

const AppointmentComponent = () => {
  return (
    <div className="App">
      <InlineWidget url="https://calendly.com/softbeez/30min" />
    </div>
  );
};

export default AppointmentComponent;
